

import React, { Fragment } from 'react';
import emailIcon from '../../image/emailIcon.png';

const EmailLogin = ({
    onEmailSignIn = () => { },
    onError = () => { },
    text = "Continue with Google",
}) => {

    const triggerLogin = async () => {
        onEmailSignIn();
        window.parent.postMessage(
            {
                sender: "emailLogin"
            },
            "*"
        );
    };

    return (
        <Fragment>
            <div className='sso-login-box' onClick={() => triggerLogin()}>
                <div className='image-logo-container'>
                    <img className='login-icons' src={emailIcon} alt="emailIcon"></img>
                </div>
                <div className='sso-name'>Continue with Email</div>
            </div>
        </Fragment>
    );
};

export default EmailLogin;






