import { tenantConstants } from "../constants";
import { tenantService } from "../service";
import _ from "lodash";

export const fetchTenantSuccess = (response) => ({
  type: tenantConstants.GET_ALL_TENANT_SUCCESS,
  payload: response,
});

export const copyTenantSuccess = (response) => ({
  type: tenantConstants.GET_ALL_TENANT_COPY_SUCCESS,
  payload: response,
});

export const fetchTenantFailure = (response) => ({
  type: tenantConstants.GET_ALL_TENANT_FAILURE,
  payload: [],
});

export const getAllTenant = () => {
  return (dispatch) => {
    return tenantService.getAllTenant().then(
      (response) => {
        response = _.orderBy(response, ["CreatedDate"], ["desc"]);
        dispatch(fetchTenantSuccess(response));
        dispatch(copyTenantSuccess(response));
        return response;
      },
      (error) => {
        dispatch(fetchTenantFailure(error));
        return error;
      }
    );
  };
};

export const filterTenant = (filter) => {
  return (dispatch, state) => {
    const data = state().tenant.allCopyTenant;
    const allCopyTenant = JSON.parse(JSON.stringify(data));
    let filtered = allCopyTenant || [];
    if (filter.search) {
      filtered = _.filter(filtered, (res) => {
        return (
          res.TenantName &&
          (res.TenantName.toLowerCase().indexOf(
            _.trim(filter.search).toLowerCase()
          ) !== -1 ||
            res.TenantID.toLowerCase().indexOf(
              _.trim(filter.search).toLowerCase()
            ) !== -1 ||
            res.CustomerName.toLowerCase().indexOf(
              _.trim(filter.search).toLowerCase()
            ) !== -1)
        );
      });
    }
    dispatch(fetchTenantSuccess(filtered));
  };
};

export const fetchTenantDetailSuccess = (response) => ({
  type: tenantConstants.GET_TENANT_DETAIL_SUCCESS,
  payload: response,
});

export const fetchTenantDetailFailure = (response) => ({
  type: tenantConstants.GET_TENANT_DETAIL_FAILURE,
  payload: {},
});

export const getTenantById = (id) => {
  return (dispatch) => {
    return tenantService.getTenantById(id).then(
      (response) => {
        dispatch(fetchTenantDetailSuccess(response || {}));
        return response;
      },
      (error) => {
        dispatch(fetchTenantDetailFailure(error));
        return error;
      }
    );
  };
};

export const createTenant = (tenant) => {
  return (dispatch) => {
    return tenantService.createTenant(tenant).then(
      (response) => {
        return response;
      },
      (error) => {
        error.error = true;
        return error;
      }
    );
  };
};

export const updateTenant = (name, tenant) => {
  return (dispatch) => {
    return tenantService.updateTenant(name, tenant).then(
      (response) => {
        dispatch(getTenantById(name));
        return response;
      },
      (error) => {
        error.error = true;
        return error;
      }
    );
  };
};

export const deleteTenant = (name, description) => {
  return (dispatch) => {
    return tenantService.deleteTenant(name, description).then(
      (response) => {
        return response;
      },
      (error) => {
        error.error = true;
        return error;
      }
    );
  };
};
