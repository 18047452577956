import React, { useEffect, useState } from 'react';
import GoogleSSOLogin from './googleSSOLogin';

import AzureAuthenticationButton from "./azure/azure-authentication-component";

import OktaSSOLogin from "./oktaSSOLogin";

import EmailLogin from "./EmailLogin";

import { userService } from "../user/service";

import './sso.scss';
import logo from '../../image/Logo-Horizontal.png';

const SSOLogin = () => {

    const [supportedLogin, setSupportedLogin] = useState([]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let tenant = params.get('tenant');
        //let platfrom = params.get('platfrom');
        if (tenant) {
            getTenantInformation(tenant)
        }else{
            setSupportedLogin(['GSuite', 'Azure', 'Okta'])
        }

    }, [])

    const getTenantInformation = (subDomain) => {

        userService.getTenantInfo(subDomain).then((res) => {
            if (res.data && res.data.Tenants && res.data.Tenants.length > 0) {
                const supportedLogin = res.data.Tenants[0].SupportedLogin
                if (supportedLogin && supportedLogin.length > 0) {
                    setSupportedLogin(supportedLogin)
                } else {
                    window.parent.postMessage(
                        {
                            sender: "emailLogin"
                        },
                        "*"
                    );
                }
            }
            else {
                window.parent.postMessage(
                    {
                        sender: "emailLogin"
                    },
                    "*"
                );
            }
        });
    };

    return (
        <div className='login-container'>
            <div className='oloid-box'>
                <img src={logo} alt="logo"></img>
                {/* <div className='text-oloid'>Oloid</div> */}
            </div>
            <div className='sso-logins'>
                <div className='sso-logins-header'>Your admin has configured following login option(s)</div>
                {
                    supportedLogin.indexOf('GSuite') > -1 &&
                    <GoogleSSOLogin />
                }
                {
                    supportedLogin.indexOf('Azure') > -1 &&
                    <AzureAuthenticationButton />
                }
                {
                    supportedLogin.indexOf('Okta') > -1 &&
                    <OktaSSOLogin />
                }
                <div className='a-divider a-divider-break'>
                    <span className="or-txt">Or</span>
                </div>
                <EmailLogin />

            </div>
        </div>
    )
}
export default SSOLogin;