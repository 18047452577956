import axios from 'axios';
import { history } from './history';
import _ from "lodash";
import Auth from '@aws-amplify/auth';
import { notification } from 'antd';

/**
 * @returns Unregister callback
 */

const hideShow = (flag) => {
  if (document.getElementById("ui_block")) {
    document.getElementById("ui_block").style.display = flag;
  }
}

const bypassLoader = [
  'reports/devices'
];

export function axiosInterceptors() {
  axios.interceptors.request.use((config) => {

    var list = _.filter(bypassLoader, (res) => { return config.url && config.url.indexOf(res) > -1 });
    if (list.length === 0) {
      hideShow('flex')
    }
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    config.headers.common['Authorization'] = user.accesstoken;

    // return Auth.currentSession()
    //   .then(session => {
    //     config.headers.common['Authorization'] = session.idToken.jwtToken;
    //     return Promise.resolve(config)
    //   }).catch(() => {
    //     return Promise.resolve(config)
    //   })
    
    return config;

  }, (error) => {
    setTimeout(() => {
      hideShow('none')
    }, 500)
    return Promise.reject(error);
  });

  axios.interceptors.response.use((response) => {
    setTimeout(() => {
      hideShow('none')
    }, 500)
    return response;
  }, (error) => {
    setTimeout(() => {
      hideShow('none')
    }, 500)
    if (error.response && 401 === error.response.status) {
      localStorage.clear();
      // if (error.response.data) {
      //   notification.error({
      //     message: 'Error',
      //     className: "toster-error",
      //     style: { width: 400, },
      //     description: error.response.data.message,
      //   })
      // }
      history.push('/login');
    }
    return Promise.reject(error);
  });
}
