import { requestDelete, requestGet, requestPost, requestPut } from '../../../services/requests';
import _ from 'lodash';
/**
 * Supports all operations exposed by the user controller.
 */

/* eslint eqeqeq: 0 */
export class TenantService {

  constructor(authEndpoint) {
    this.authEndpoint = authEndpoint;
  }

  getAllTenant() {
    const url = this.authUrl('/tenants');
    return requestGet(url, {}, true)
      .then((res) => {
        return res.data.tenants
      });
  };

  getTenantById(id) {
    const url = this.authUrl(`/tenants/${id}`);
    return requestGet(url)
      .then((res) => {
        return res.data
      });
  };

  createTenant(tenant) {
    const url = this.authUrl('/tenants');
    return requestPost(url, tenant)
      .then((res) => {
        return res
      });
  };

  updateTenant(name, tenant) {
    const url = this.authUrl(`/tenants/${name}`);
    return requestPut(url, tenant)
      .then((res) => {
        return res
      });
  };


  deleteTenant(name, description) {
    const url = this.authUrl(`/tenants/${name}?description=${description}`);
    return requestDelete(url)
      .then((res) => {
        return res
      });
  };


  authUrl(append) {
    return this.authEndpoint + append;
  }
}
